//cn.js
const cn = {
    "baseurl": "",
    "lang": "cn",
    "head_title": "专业观众会员中心——2025 SIAL西雅国际食品和饮料展览会",
    "head_description": "SIAL西雅展(上海)将于2025年5月19日-21日在上海新国际博览中心举办，展览面积将达200,000㎡，预计吸引全球75+国家和地区的5,000+展商和全球110+国家和地区的180,000+专业人士。同期还将举办SIAL世界食品产业峰会和赛事及活动。SIAL 西雅展(深圳)将于2025年9月1日-3日，在深圳会展中心(福田)举办，展览面积将达到60,000平方米，预计吸引全球50个国家和地区的1,500+展商和67,000位专业人士，同期还将举办SIAL世界食品产业峰会和赛事及活动。",
    "head_keywords": "食品展2025,专业买家，SIAL西雅展，会员中心，买家入口，食饮选品",
    "￥": "￥",
    "home": "概览",
    "confirm": "确 定",
    "cancel": "取 消",
    "please select payment": "请先选择支付方式",
    "select payment": "选择支付方式",
    "paid success": "付费成功",
    "paid successfully": "已支付成功",
    "none payment": "未支付",
    "paid": "已支付",
    "Wechat Pay": "微信支付",
    "Alipay Pay": "支付宝支付",
    "Offline payment": "线下支付",
    "coupon": "优惠券",
    "input coupon": "请输入您的观看优惠码",
    "submit payment": "立即支付",
    "open Alipay payment interface": "请在打开的支付宝支付界面进行支付",
    "Bank Transfer Information": "线下支付信息",
    "paying": "支付中...",
    "Pay with a swipe on wechat": "微信扫一扫支付",
    "Tickets purchased successfully, please pay offline": "购票成功，请于线下支付",
    "You have paid successfully, to My Ticket": "您已支付成功，请于“我的门票”查看入场码！",
    "Warm reminder": "温馨提示",
    "payment within 2 hours": "请于2小时内完成支付，订单到期将自动取消。",
    "Order information": "订单信息",
    "Order number": "订单编号",
    "truename": "姓名",
    "mobile": "手机",
    "email": "邮箱",
    "number": "数量",
    "unit price": "单价",
    "total order price": "订单总价",
    "Discount amount": "优惠金额",
    "Amount actually paid": "实际支付金额",
    "View order": "查看订单",
    "live time": "直播时间",
    "Please log in": "请先登录",
    "Log back in": "重新登录",
    "The request timed out. Please refresh and try again": "请求超时，请刷新后重试",
    'date': {
        'D': '天',
        'h': '时',
        'm': '分',
        's': '秒',
    },
    "livepay tips": "购买后视频观看有效期为21天"
}
 
export default cn